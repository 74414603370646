import React from "react";
import BImage from "gatsby-background-image";
import Loadable from "@loadable/component";

import Layout from "../../components/common/layout";
import SEO from "../../components/common/seo";
import Readmore from "../../components/common/readMore";
import useImage from "../../hooks/use-image";
const Carousel = Loadable(() => import("../../components/common/carousel"), {
  fallback: <div>Loading...</div>,
});
const Benefits = Loadable(() => import("../../components/careers/benefits"), {
  fallback: <div>Loading...</div>,
});

export default function Careers() {
  const { careersSM, careersMD, careersLG, careersXL } = useImage();
  const careerSrc = [
    careersSM.sharp.fluid,
    { ...careersMD.sharp.fluid, media: `(min-width: 500px)` },
    { ...careersLG.sharp.fluid, media: `(min-width: 1024px)` },
    { ...careersXL.sharp.fluid, media: `(min-width: 1200px)` },
  ];

  return (
    <Layout>
      <SEO
        title="Careers"
        description="We are as much a family as we are a team, collectively engineering technology that improves the lives and work of the people who use it."
      />
      <BImage
        Tag="section"
        fluid={careerSrc}
        className="careers-hero text-white relative p-6 lg:pl-20 lg:pt-24"
      >
        <h1 className="relative z-10 text-xl font-medium 2xl:text-2xl">
          Careers
        </h1>
        <h2 className="relative z-10 uppercase font-bold text-4xl md:text-5xl lg:text-6xl xl:text-8xl 3xl:text-9xl">
          Balancing Great work
          <br /> with a Great life
        </h2>
        <Readmore minHeight={108}>
          <p className="relative z-20 mt-6 leading-relaxed text-lg 2xl:text-xl max-w-3xl">
            Clarity engages talented people in solving problems using cutting
            edge technologies. We foster an inclusive culture so that our
            solutions address as many aspects of the problem as possible.
            Because people work their best when they feel happy and secure, we
            encourage independence, innovation, mentorship, and conversation.
          </p>
        </Readmore>
        <span className="gradient-overlay-left z-0" />
      </BImage>
      <Benefits />
      <Carousel />
    </Layout>
  );
}
